<template>
    <v-dialog v-model="isVisible"
        persistent
        :content-class="( type !== 'qr' ? 'input-dialog' : 'qr-dialog')"
        :fullscreen="windowWidth < 960">

        <v-btn color="warning"
            fixed
            :right="windowWidth < 960"
            :style="{'z-index': 2, width: 'unset', 'margin-top': (windowWidth < 960 ? '1em' : '-1.5em'), 'margin-left': '-2.5em', height: '44px'}"
            @click="isVisible = !isVisible">
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <BCard v-if="type === 'qr'"
            :size="inputData.size"
            :gender="inputData.gender"
            :name="inputData.name"
            :surname="inputData.surname"
            :street="inputData.street"
            :zipcode="inputData.zipcode"
            :country="inputData.country"
            :city="inputData.city"
            :role="inputData.role"
            :company="inputData.company"
            :phones="inputData.numbers ? inputData.numbers.split(',') : []"
            :mails="inputData.mails ? inputData.mails.split(',') : []" />

        <InputMask v-else
            :id="id"
            :type="type"
            :title="title"
            :inputData="inputData"
            :fixedData="fixedData"
            :confirmable="confirm"
            :preValidate="preValidate"
            v-on:edit-item-saved="itemSaved(arguments[0])" />
    </v-dialog>
</template>


<script>
import { mapActions } from 'vuex'
import InputMask from '@/components/InputMaskTabs.vue'

export default {
    props: {
        id: {
            default() {
                return -1
            },
            type: Number,
        },
        dialog: Boolean,
        type: String,
        title: String,
        inputData: Object,
        fixedData: Object,
        modified: Function,
        confirm: {
            default() {
                return true
            },
            type: Boolean,
        },
        preValidate: {
            default() {
                return Promise.resolve(true)
            },
            type: Function,
        },
    },

    data() {
        return {
            windowWidth: 0,
            isVisible: this.dialog,
        }
    },

    components: {
        InputMask,
        BCard: () => import('@/components/BCard.vue'),
    },

    methods: {
        ...mapActions({
            showInputDialog: 'input/showDialog',
        }),
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        itemSaved(result) {
            const item = result.data
            this.modified(result.id, item)
            this.showInputDialog(false)
        },
    },

    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },

    watch: {
        dialog() {
            this.isVisible = this.dialog
        },

        isVisible() {
            if (!this.isVisible) {
                this.showInputDialog(null)
            }
        },
    },
}
</script>


<style lang="stylus">
    .input-dialog, .qr-dialog
        max-width 800px
        position relative

    .v-dialog.qr-dialog
        width unset

    @media (max-width: 959px)
        .input-dialog
            max-width 100%
            margin 0
            max-height none
</style>
